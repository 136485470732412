// @ts-ignore
import { Ziggy } from '@js/ziggy.js';
// @ts-ignore
import { route as routeFn } from 'ziggy-js';

declare global {
  var route: typeof routeFn;
}

export function route(
  name: string,
  params: any = {},
  absolute: boolean = false,
  config: any = Ziggy
): string {
  return routeFn(name, params, absolute, config);
}
